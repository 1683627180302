import React from "react";
import {Slider } from "@fluentui/react";
import { useRecoilState } from "recoil";
import { inputDataAtom } from "../state/input";
import {SliderValueFormat} from "../util";
import { colorStyles } from "./Config";
import { cutDecimal, langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";


export function HoistUtilization() {
    const [languageMap] = useLanguage();
    const  [inputData, setInputData] = useRecoilState(inputDataAtom)

    const onUtilizationChanged = (newUtilization:number) => {setInputData(v=>({
        ...v,
        utilization:newUtilization
    }))};

    return (
        <Slider styles={colorStyles} label={langaugeConvert("Utilization %", languageMap, false)} min={1} max={100} 
                showValue valueFormat={SliderValueFormat} value={inputData.utilization} onChange={onUtilizationChanged}/>
    );
}