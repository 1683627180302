import React from "react";
import { useRecoilState } from "recoil";
import { inputDataAtom } from "../state/input";
import { saveDataAtom } from "../state/event";
import { IlanguageText, languageMap } from "../state/language";
import { IMenu, menuAtom } from "../state/menu";

import { savedinputConfig, ISavedConfig, editorMode } from "../state/saveInput";
import { hoistsDataAtom, IHoistDataItem } from "../state/hoist";
import { Fetch, empty, getRandomCode } from "./../util";
import { InputCalculated } from "../views/Calculated";


interface IConfig {
  config: {
    inputData : InputCalculated;
    hoists : IHoistDataItem[];
  }
}

interface ILoadConfig {
    id : number;
    basicConfig : string;
}

interface IConfigId {
  id : number;
}

interface ILoadConfigRequest {
  email: string;
  code: string;
}

interface ILanguageRequest {
  lang: string;
}


interface IProps {
    children?: React.ReactNode;
  }

export  const colorStyles = {
    activeSection: {
        backgroundColor: "#FDB913",
        
    },
    inactiveSection: {
      backgroundColor: "#ffdf91",
      
   }
};


export  const colorTextFieldStyles = {
  fieldGroup: {
      borderColor: "#ffdf91"
  }
};



export const getBasicConfig = () => window.location.origin as string + process.env.REACT_APP_API_PATH as string;
export const saveConfig = () => window.location.origin as string + process.env.REACT_APP_API_SAVE_PATH as string;
export const loadConfig = () => window.location.origin as string + process.env.REACT_APP_API_LOAD_PATH as string;
export const menuConfig = () => window.location.origin as string + process.env.REACT_APP_API_GETMENU_PATH as string;
export const languageConfig = () => window.location.origin as string + "/api/Ttc/GetLanguage" as string;


export const Config = (props:IProps) => {
   const { children } = props;
 
    const [inputData, setInputData] = useRecoilState(inputDataAtom);
    const [hoistData, setHoistData] = useRecoilState(hoistsDataAtom);
    const [saveData, setSaveData] = useRecoilState(saveDataAtom);
    const [menuData, setmenuData] = useRecoilState(menuAtom);
    const [state, response,,defaultConfig] = Fetch<ILoadConfig[],empty>("GET",getBasicConfig());
    const [stateMenu, responseMenu, responseErrorMenu, menuConfigRequest] = Fetch<IMenu[],empty>("GET",menuConfig());
    const [stateSave, responseSave, responseError, saveConfigRequest] = Fetch<ISavedConfig[], ISavedConfig>("POST",saveConfig());
    const [stateLoad, responseLoad, responseErrorLoad, loadConfigRequest] = Fetch<ISavedConfig[], ILoadConfigRequest>("GET",loadConfig());
    const [statelanguage, responseLanguage, responseErrorLanguage, LanguageConfigRequest] = Fetch<IlanguageText[], ILanguageRequest>("GET",languageConfig());
    const [saveInput, setSaveInput] = useRecoilState(savedinputConfig);
    const [editMode, setEditMode] = useRecoilState(editorMode);
    const [language, setlanguage] = useRecoilState(languageMap);
   
    const queryParams = new URLSearchParams(window.location.search)
    const term = queryParams.get("term")
    const location = queryParams.get("location")


    //init with 
    React.useEffect(() => {
      if (queryParams.get("code") !== null && queryParams.get("type") === 'static' ){
        
        loadConfigRequest({email:"", code:queryParams.get("code")!});
      }
      else
      {
        
        defaultConfig(true);
      }

      //load language
      let lang = queryParams.get("lang")
      
      if (lang === null)
      {
        lang = "en"
      }

      LanguageConfigRequest({lang:lang});


      //start menu request
      menuConfigRequest(true);

    // eslint-disable-next-line
    }, []);

    // init menu
    React.useEffect(() => {
      if (stateMenu === "SUCCESS"){
        if (responseMenu !== undefined)
        {
          setmenuData(responseMenu);
        }
      }
    // eslint-disable-next-line
    }, [stateMenu]);



    //load data
    React.useEffect(() => {

      if (stateLoad === "SUCCESS")
      {
        if (responseLoad !== undefined){
          if (responseLoad.length > 0){
            let obj = JSON.parse(responseLoad[0].configData!) as IConfig;
            setInputData(obj.config.inputData);
            setHoistData(obj.config.hoists);
            setSaveInput((...prevState) => ({prevState,name:responseLoad[0].name, email:responseLoad[0].email, id:responseLoad[0].id, code:responseLoad[0].code, password:responseLoad[0].password, sendEmail:responseLoad[0].sendEmail, pathname:window.location.pathname, type:'static'}));

            if (queryParams.get("email") !== null && queryParams.get("code") !== null && queryParams.get("pass") !== null){
              if (queryParams.get("email") === responseLoad[0].email && queryParams.get("code") === responseLoad[0].code && queryParams.get("pass") === responseLoad[0].password){
                setEditMode({editMode:"Edit"})
              }
            }

            if (queryParams.get("email") !== null && queryParams.get("code") !== null && queryParams.get("pass") === null){
              if (queryParams.get("email") === responseLoad[0].email && queryParams.get("code") === responseLoad[0].code && queryParams.get("pass") === null){
                setEditMode({editMode:"View"})
              }
            }
          }
        }
      }

      if (stateLoad === "ERROR"){
        console.log("Error Fetch Config Data")
      }
    // eslint-disable-next-line
    }, [stateLoad]);




    //Init data
    React.useEffect(() => {

      if (state === "SUCCESS")
      {
        if (response !== undefined){
          let obj = JSON.parse(response[0].basicConfig) as IConfig;
          setInputData(obj.config.inputData);
          setHoistData(obj.config.hoists);
        }
      }

      if (state === "ERROR"){
        console.log("Error Fetch Config Data")
      }
    // eslint-disable-next-line
    }, [state]);


    //Init language
    React.useEffect(() => {

      if (statelanguage === "SUCCESS")
      {
        if (responseLanguage !== undefined){
          let MapData = new Map<string,IlanguageText>();

          for (let i=0;i<responseLanguage.length;i++){
            MapData.set(responseLanguage[i].languageDefault,responseLanguage[i]); 
          }   
          setlanguage({languageMap:MapData});
        }
      }

      if (statelanguage === "ERROR"){
        console.log("Error Fetch Language Data")
      }
    // eslint-disable-next-line
    }, [statelanguage]);



    //save data success
    React.useEffect(() => {
      if (stateSave === "SUCCESS"){
        if (responseSave !== undefined)
        {
          setSaveInput((...prevState) => ({prevState,name:responseSave[0].name, email:responseSave[0].email, id:responseSave[0].id, code:responseSave[0].code, userName:responseSave[0].userName, sendEmail:responseSave[0].sendEmail, pathname:window.location.pathname, type:'static'}));
        }  
      }
    // eslint-disable-next-line
    }, [stateSave]);


    //save data
    React.useEffect(() => {
      if (saveData.onSave === true){
        const configData =  JSON.stringify({config: {inputData : inputData, hoists : hoistData}});
        saveConfigRequest({id:saveInput.id, name:saveInput.name, email:saveInput.email, code:saveInput.code, configData: configData, userName:saveInput.userName, token:saveInput.token, sendEmail:saveInput.sendEmail, pathname:window.location.pathname, type:'static'});
      }

      // eslint-disable-next-line
    }, [saveData]);





return(<>{children}</>)
}