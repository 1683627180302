import React, { useCallback, useState } from "react";
import { CardsHoists } from "./CardsHoists";
import { hoistsDataAtom, IHoistDataItem } from "../../state/hoist";
import { useRecoilValue, useRecoilState } from "recoil";
import { inputDataAtom } from "../../state/input";
import { Panel, PanelType, CommandBar, ICommandBarItemProps, setVirtualParent, IButtonProps, Stack } from "@fluentui/react";
import { CardDetails, ICopyofHoistDataItem } from "./CardDetails";
import { CardDownload } from "./CardDownload";
import { CardUpload} from "./CardUpload";
import { v4 as uuidv4 } from 'uuid';
import { savedinputConfig, ISavedConfig, editorMode } from "../../state/saveInput";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { FontIcon } from "@fluentui/react/lib/Icon";
import './Card.css';
import { langaugeConvert } from "./../../util";
import { useLanguage } from "./../../components/useLanguage";
import ReactHtmlParser from 'html-react-parser';
import { decode } from 'js-base64';
import { menuAtom } from "../../state/menu";




interface Props
{
    data: IHoistDataItem[];
}

type openOptions = "edit" | "upload" | "download";

const headText = (htmloutput:string):string => {
  let outPutHtml = decode(htmloutput);
  return outPutHtml;
}

const iconClass = mergeStyles({
  fontSize: 16,
  height: 20,
  width: 20,
  color: "#FDB913",
  cursor: "pointer",
  marginLeft: "8px",
  fontWeight: "600",
});

export const EditCalculatedPage = (props: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [isDownOpen, setIsDownOpen] = useState(false);
    const [iseditMode, setEditMode] = useRecoilState(editorMode);
 
    const [id, setId] = useState<string>();
    const input = useRecoilValue(inputDataAtom);
    const { data } = props;
    const [readHoistData, setHoistData] = useRecoilState(hoistsDataAtom);
    const [copyItem, setCopyItem] = useState<ICopyofHoistDataItem>();
    const [textRead, setTextRead] = useState(false);
    const [languageMap] = useLanguage();
    const [menuData, setmenuData] = useRecoilState(menuAtom);


    const _items: ICommandBarItemProps[] = [
        {
          key: 'newItem',
          text: langaugeConvert("New", languageMap, false),
          cacheKey: 'myCacheKey',
          iconProps: { iconName: 'Add' },
          onClick: () => newItem(),
        },
        {
          key: 'Save',
          text: langaugeConvert("Save", languageMap, false),
          iconProps: { iconName: 'Upload' },
          onClick: () => setIsUploadOpen(true)
        },
      ];



    const onMenuButtonClick = useCallback((id) => {
        setId(id);
        setIsMenuOpen(true);
    }, []);

    const onDismiss = useCallback((option:openOptions) => {
        if (option === "edit"){
          setIsMenuOpen(false);
          // undo hoist data
          const arr = [...props.data];
          arr[arr.findIndex(i => i.id === copyItem?.itemId!)] = copyItem?.itemHoist!;
          setHoistData(arr);

          setHoistData(arr);
        }
        else if (option === "download"){
          setIsDownOpen(false);
        }
        else if (option === "upload"){
          setIsUploadOpen(false);
        }

      }, [data]);


    const onChangeEdit = (item:IHoistDataItem) => {
        const arr = [...props.data];
        arr[arr.findIndex(i => i.id === item.id)] = item;
        setHoistData(arr);
    }
    
    const newItem = () =>{
        const arr = props.data.slice();
        arr.push({id:uuidv4(), name:"New", timeNormalLoad:0,timeHeavyLoad:0,img:"",imgId:"",showImg:false})
        setHoistData(arr);
    }

    const deleteItem = (id:string) =>{
        const arr = props.data.filter((t) => t.id !== id);
        setHoistData(arr);
        setIsMenuOpen(false);
    }

    const onCopyitem = (itemCopyInfo:ICopyofHoistDataItem) => {
      setCopyItem(itemCopyInfo);
    }


    const onOk = () =>{
      setIsMenuOpen(false);
  }
  
  const onCancel = () =>{
    setIsUploadOpen(false);
  }


  const headertext = (read:boolean) => {

    return(
        <div>
            <div style={{fontSize: "22px", fontWeight: "600", paddingBottom:"12px", display: "inline-block"}}>{ReactHtmlParser(headText(langaugeConvert("htmlHeadText", languageMap, false, menuData))) /*ReactHtmlParser(headText(props.setting.htmlHeadText))*/}</div>
            <div style={{display: "inline-block"}}>
            {
            read === true ?               
               <div onClick={() => setTextRead(!textRead)}><FontIcon aria-label="Settings" iconName="ChevronUp" className={iconClass} />
           </div>
           :
                <div onClick={() => setTextRead(!textRead)}><FontIcon aria-label="Settings" iconName="ChevronDown" className={iconClass} /></div>
            }</div>
        </div>
    )
}

const Overviewtest = (read:boolean) => {
    return(
      <>
      <div>
        {headertext(read)}
        {   read === true ?      
              ReactHtmlParser(headText(langaugeConvert("htmlBodyText", languageMap, false, menuData)))
            :
              null    
        }
      </div>
     </>
    )
}



    return (
        <>
        {Overviewtest(textRead)}

        <Stack tokens={stackTokens}>
            <CommandBar
                items={_items}
                overflowButtonProps={overflowProps}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
        </Stack>
            <CardsHoists data={data} input={input} onClick={onMenuButtonClick} editMode={iseditMode.editMode} />

            <Panel isOpen={isMenuOpen} onDismiss={() => onDismiss("edit")} type={PanelType.smallFixedFar} headerText={ langaugeConvert("Edit data", languageMap, false)}>
                <CardDetails title="Edit data" data={data} id={id} onCopy={(itemInfo) => onCopyitem(itemInfo) }  onChange={(item) => onChangeEdit(item)} onDelete={(id) => deleteItem(id)} onOk={() => onOk()}/>
            </Panel>

            <Panel isOpen={isUploadOpen} onDismiss={() => onDismiss("upload")} type={PanelType.smallFixedFar} headerText={ langaugeConvert("Upload data", languageMap, false)}>
                <CardUpload title="Edit data" data={data} id={id} onChange={(item) => onChangeEdit(item)} onDelete={(id) => deleteItem(id)} onCancel={() => onCancel()}/>
            </Panel>
        </>
        );
}



  const stackTokens = { padding: 0 };
  const overflowProps: IButtonProps = { ariaLabel: 'More commands' };


  