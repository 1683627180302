import { atom } from "recoil";
import { IHoistDataItem } from "./../state/hoist";

export interface ISaveData
{
    onSave: boolean;
}

export interface IScreenEventAtom
{
    type: string;
}

export interface ISetUnderline
{
    path: string;
}


export const saveDataAtom = atom<ISaveData>({
    key: "SaveDataAtom",
    default: {onSave:false}
});

export const screenEventAtom = atom<IScreenEventAtom>({
    key: "ScreenEventAtom",
    default: {type:"empty"}
});


export const setUnderline = atom<ISetUnderline>({
    key: "SetUnderlineAtom",
    default: {path:"test"}
});


