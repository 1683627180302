import { Stack, TextField, ITextFieldStyles, PrimaryButton, Checkbox, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useRef } from "react";
import { IHoistDataItem } from "../../state/hoist";
import { Fetch, empty } from "./../../util";
import { FileUploader } from "react-drag-drop-files";
import { v4 as uuidv4 } from 'uuid';
import { displayLocaleFormatDigt } from "./../../util";
import { langaugeConvert } from "./../../util";
import { useLanguage } from "./../../components/useLanguage";
import ReactHtmlParser from 'html-react-parser';
import { decode } from 'js-base64';
import { menuAtom } from "../../state/menu";
import { useRecoilValue, useRecoilState } from "recoil";




export interface ICopyofHoistDataItem
{
    itemHoist: IHoistDataItem | undefined;
    itemId: string;
}

interface IBarMessage
{
  open: boolean;
  msg?: string;
  type?: MessageBarType; 
}

interface Props
{
    title: string;
    id?: string;
    data: IHoistDataItem[];
    onChange?: (hoistDataItem:IHoistDataItem) => void;
    onDelete?: (id:string) => void;
    onOk?: () => void;
    onCopy?: (itemInfo:ICopyofHoistDataItem) => void;
}

interface IRequestUpload{
  data: FormData;
}

const arr_images:string[] = ["image/jpg","image/jpeg", "image/png", "image/gif"]

const stackTokens = { childrenGap: 15 };
const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 300 } };

export const uploadPic = () => window.location.origin as string + process.env.REACT_APP_API_UPLOAD_PATH as string;

export const CardDetails = (props:Props) => {
    const { title, id, data } = props;
    const [headerFieldValue, setHeaderFieldValue] = React.useState('');
    const [normalFieldValue, setNormalFieldValue] = React.useState('');
    const [heavyFieldValue, setHeavyFieldValue] = React.useState('');
    const [imgFieldValue, setimgFieldValue] = React.useState('');
    const [showImgFieldValue, setshowImgFieldValue] = React.useState<boolean>(false);
    
    const [selectedFile, setSelectedFile] = React.useState<File>();
    const [imgUrl,setImgUrl] = React.useState('');
    const [copyImg,setCopyImg] = React.useState('');
    const [imgSelected,setImgSelected] = React.useState<boolean>(false);
    const [formData,setFormData] = React.useState<FormData>();
    const [idRandom,setIdRandom] = React.useState("");
    const [showMessageBar, setShowMessageBar] = React.useState<IBarMessage>({open:false, msg:""});

    const [stateupload, responseUpload, responseUploadError, uploadRequest] = Fetch<undefined,FormData>("POST",uploadPic(),undefined,true);
    const [languageMap] = useLanguage();
    const [menuData, setmenuData] = useRecoilState(menuAtom);



    
    React.useEffect(() => {
        if (id !== undefined)
        {
            const hoistDataItem = data.find(x => x.id === id);
            setHeaderFieldValue(hoistDataItem?.name!);        
            setNormalFieldValue(hoistDataItem!.timeNormalLoad.toString());
            setHeavyFieldValue(hoistDataItem!.timeHeavyLoad.toString());
            setshowImgFieldValue(hoistDataItem!.showImg)
            setCopyImg(hoistDataItem!.img!);
            props.onCopy!({itemHoist:hoistDataItem, itemId:id});
        }
    }, [id]);

    //load data from api success
    React.useEffect(() => {
      if (stateupload === "SUCCESS")
      {
        if (props.onOk !== undefined){
          
          if (props.onChange !== undefined)
          {
            props.onChange({
                id:id!, 
                name:headerFieldValue,
                timeNormalLoad: parseFloat(normalFieldValue),
                timeHeavyLoad: parseFloat(heavyFieldValue),
                img: idRandom,
                imgId: "",
                showImg: showImgFieldValue
            });
        } 
          setSelectedFile(undefined);
          props.onOk();
        }
      }
  }, [stateupload]);


    //changed some textfields
    React.useEffect(() => {
        if (id !== undefined)
        {
            if (props.onChange !== undefined && normalFieldValue !== "")
            {
              props.onChange({
                  id:id!, 
                  name:headerFieldValue,
                  timeNormalLoad: parseFloat(normalFieldValue),
                  timeHeavyLoad: parseFloat(heavyFieldValue),
                  img: imgUrl !== "" ? imgUrl : copyImg,
                  imgId: "",
                  showImg: showImgFieldValue
              });
          }        }
    }, [headerFieldValue, normalFieldValue, heavyFieldValue,imgFieldValue,imgUrl,showImgFieldValue]);


  const onDelete = () =>{
    if (props.onDelete !== undefined && id !== undefined){
      props.onDelete(id);
    }
  } 


  const onOk = () =>{
    if (props.onOk !== undefined){

      if (selectedFile === undefined){
        props.onOk();
      }
      else
      {
        uploadRequest(formData);
      }
    }  
  }

  const onCancel = () =>{
    setImgUrl(copyImg);
    setImgSelected(false);
    setSelectedFile(undefined);
  }

  const setMessagebar = (msg:string, type:MessageBarType) =>{
    setShowMessageBar(prevCheck => ({prevCheck, open:true,msg:msg, type:type}));
  }


  const changeHandler = (file:File) => {
    if ((file.size / (1024*1024)) > 1)
    {
      setShowMessageBar(prevCheck => ({prevCheck, open:true,msg:"The image is too large", type:MessageBarType.error}));
    }
    else if (arr_images.indexOf(file.type) === -1){
      setShowMessageBar(prevCheck => ({prevCheck, open:true,msg:"Wrong image format", type:MessageBarType.error}));
    }
    else
    {
      setShowMessageBar(prevCheck => ({prevCheck, open:false}));
      const id = uuidv4();
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("formfile", file);
      formData.append("filename", file.name);
      formData.append("filetype", file.type);
      formData.append("id", id);
      setImgUrl(URL.createObjectURL(file));
      setImgSelected(true);
      setFormData(formData);
      setIdRandom(id);
    }
   };

   const onChangeShowPictureCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
    if (isChecked !== undefined){
      setshowImgFieldValue(isChecked);
    }
  }

    const styleUploadArea = () => {
        return (<div className="upload-container"><div className="upload-content"><p>{langaugeConvert("Choose a file or drag it here. Size max 1mb", languageMap, false)}</p></div></div>)
    }

    const onChangeFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

            switch (event.currentTarget.dataset.label) {
                case "header":
                    setHeaderFieldValue(newValue || '')
                  break;
              
                case "normal":
                    setNormalFieldValue(newValue?.replaceAll(",",".") || '')
                  break;

                case "heavy":
                    setHeavyFieldValue(newValue?.replaceAll(",",".") || '')
                    break;

                case "img":
                    setimgFieldValue(newValue || '')
                    break;
      

                default:
                  console.log();
              }
        },
        [props],
    );

    return(
        <>
<Stack tokens={stackTokens}>

<div style={{width:"100%", height:"20px"}}>  
      { showMessageBar?.open === true ?  
      <MessageBar messageBarType={showMessageBar.type} isMultiline={false} onDismiss={()=> setShowMessageBar(prevCheck => ({prevCheck, open:false}))} dismissButtonAriaLabel="Close" >
          {showMessageBar.msg}
      </MessageBar>
      : null}
    </div>

      <TextField
        label={langaugeConvert("Header", languageMap, false)}
        data-label="header"
        value={headerFieldValue}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />
      <TextField
        label={langaugeConvert("Normal load in seconds", languageMap, false)}
        data-label="normal"
        value={displayLocaleFormatDigt(normalFieldValue)}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />
      <TextField
        label={langaugeConvert("Heavy load in seconds", languageMap, false)}
        data-label="heavy"
        value={displayLocaleFormatDigt(heavyFieldValue)}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />

      <FileUploader label="" children={styleUploadArea()}  handleChange={(file:File) => changeHandler(file)} name="file" types={["JPG","JPEG", "PNG", "GIF"]} />

      <Checkbox label={langaugeConvert("Show picture", languageMap, false)} onChange={onChangeShowPictureCheckbox} checked={showImgFieldValue} />

      <div className="button-container">
        <PrimaryButton text={langaugeConvert("Update", languageMap, true)} onClick={() => onOk()} style={{width:"110px", marginRight:"5px"}} />
        <PrimaryButton text={langaugeConvert("Delete", languageMap, true)} onClick={() => onDelete()} style={{width:"110px",marginRight:"5px"}} />
        {  imgSelected === true ?
          <PrimaryButton text={langaugeConvert("Cancel", languageMap, true)} onClick={() => onCancel()} style={{width:"110px"}} />
          :
          null
        }
      </div>

    </Stack>


    </>
    )
}