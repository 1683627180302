import React from "react";
import {Slider, } from "@fluentui/react";
import { useRecoilState } from "recoil";
import { inputDataAtom } from "../state/input";
import { colorStyles } from "./Config";
import { langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";




export function Hoists() {
    const [languageMap] = useLanguage();    
    const  [inputData,setInputData] = useRecoilState(inputDataAtom)

    const onHoistsChanged = function(newNoHoists:number) 
        {setInputData(v => ({
        ...v,
        noOfHoists: newNoHoists
    }))};


      


    return (
        <Slider styles={colorStyles} label={langaugeConvert("Number of hoists", languageMap, false)} min={1} max={500} showValue onChange={onHoistsChanged} value={inputData.noOfHoists} />
    );
}