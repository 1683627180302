import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useEffect, useCallback } from "react";
import { PrimaryButton } from "@fluentui/react";


interface Props
{
    title: string;
    onSave: (token:string) => void;
}

export const ReCaptchaComponent = (prop:Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('submit');
    prop.onSave(token);
  }

  return(
    <PrimaryButton text={prop.title} onClick={() => handleReCaptchaVerify()} style={{width:"100px"}} />
  )
};