import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    min-width: 100vw;
    grid-template-columns: 300px 1fr;
    grid-template-areas: "sidebar main";
`;

export const ContainerMobile = styled.div`
    display: flex;
    justify-content: center;    
`;


export const SideBar = styled.aside`
    grid-area: sidebar;
    padding: 10px;
    `;

export const Main = styled.main`
    grid-area: main;
    padding: 10px;
    
`;
