import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";
import { ThemeProvider, PartialTheme } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Initialize icons in case this example uses them
initializeIcons();

const myTheme: PartialTheme = {
  palette: {
    themePrimary: '#fdb913',
    themeLighterAlt: '#fffcf6',
    themeLighter: '#fff4d9',
    themeLight: '#feeab8',
    themeTertiary: '#fdd672',
    themeSecondary: '#fdc330',
    themeDarkAlt: '#e3a812',
    themeDark: '#c08e0f',
    themeDarker: '#8d690b',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#595959',
    neutralSecondary: '#373737',
    neutralPrimaryAlt: '#2f2f2f',
    neutralPrimary: '#000000',
    neutralDark: '#151515',
    black: '#0b0b0b',
    white: '#ffffff',
  }
};


ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey="6LeRZlkhAAAAAKwCW-SC-vdGoImKSbV-g51BaXhU">
        <ThemeProvider theme={myTheme}>
          <App />
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  </RecoilRoot>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
