import { atom } from "recoil";

export interface IMenu
{
    menuName: string;
    menuTo: string;
    menuIndex: number;
    menuActive: boolean;
    menuComponentName: string;
    menuComponentId: string;
}

export const menuAtom = atom<IMenu[]>({
    key: "MenuAtom",
    default: []
});