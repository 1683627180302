import React from "react";
import { useRecoilState } from "recoil";
import { screenEventAtom } from "./../state/event";


export interface IViewport{
    widthType: sizes;
}

interface IProps {
   children?: React.ReactNode;
 }

export type sizes = "w_xm" | "w_sm" | "w_md" | "w_lg" | "w_xl"

//w_xm (extra small devices - screen width less than 576px)
//w_sm (small devices - screen width equal to or greater than 576px)
//w_md (medium devices - screen width equal to or greater than 768px)
//w_lg (large devices - screen width equal to or greater than 992px)
//w_xl (xlarge devices - screen width equal to or greater than 1200px)


export const UseViewport = (props:IProps) => {
    const { children } = props;
    const [, setWidth] = React.useState(window.innerWidth);
    const [, setHeight] = React.useState(window.innerHeight);
    const [readScreenValue, setScreenValue] = useRecoilState(screenEventAtom);

    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

     if (window.innerWidth <= 576)
        {
           if (readScreenValue.type !== "w_xm") {
            setScreenValue({type:"w_xm"});
           }
        }
 
      if (window.innerWidth > 577)
      {
         if (readScreenValue.type !== "empty") {
         setScreenValue({type:"empty"});
         }
      }

    };

    React.useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, [readScreenValue]);
  

    //Init
    React.useEffect(() => {
      handleWindowResize();
   }, []);






    return(<>{children}</>)

  };