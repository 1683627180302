import { Stack, TextField, ITextFieldStyles, PrimaryButton, MessageBar, MessageBarType, Checkbox, Separator } from "@fluentui/react";
import React, { useRef, useCallback } from "react";
import { IHoistDataItem } from "../../state/hoist";
import { saveDataAtom } from "../../state/event";
import { savedinputConfig } from "../../state/saveInput";
import { useRecoilState } from "recoil";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import { FontIcon } from "@fluentui/react/lib/Icon";
import { ReCaptchaComponent } from "./../../components/ReCaptchaComponent";
import { validateEmail } from "./../../util";
import { langaugeConvert } from "./../../util";
import { useLanguage } from "./../../components/useLanguage";
import ReactHtmlParser from 'html-react-parser';
import { menuAtom } from "../../state/menu";


interface Props
{
    title: string;
    id?: string;
    data: IHoistDataItem[];
    onChange?: (hoistDataItem:IHoistDataItem) => void;
    onDelete?: (id:string) => void;
    onCancel?: () => void;
}

interface IBarMessage
{
  open: boolean;
  msg?: string;
  type?: MessageBarType; 
}

const stackTokens = { childrenGap: 15 };
const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 300 } };

const iconClass = mergeStyles({
  fontSize: 30,
  height: 30,
  width: 30,
  marginTop: "20px",
  cursor: "pointer"
});



export const CardUpload = (props:Props) => {
    const { title, id, data } = props;
    const [nameFieldValue, setNameFieldValue] = React.useState('');
    const [emailFieldValue, setEmailFieldValue] = React.useState('');
    const [passFieldValue, setPassFieldValue] = React.useState('');
    const [sendEmailFieldValue, setSendEmailFieldValue] = React.useState<boolean>(true);
    const [usernameFieldValue, setUsernameFieldValue] = React.useState('');
    const [saveData, setSaveData] = useRecoilState(saveDataAtom);
    const [saveInput, setSaveInput] = useRecoilState(savedinputConfig);
    const myRef = useRef<HTMLDivElement>(null);
    const [showMessageBar, setShowMessageBar] = React.useState<IBarMessage>({open:false, msg:""});
    const [languageMap] = useLanguage();
    const [menuData, setmenuData] = useRecoilState(menuAtom);


  const onSave = (token:string) => {

    if (validateSave()){
      setSaveInput((...prevState) => ({...prevState, name:nameFieldValue, email:emailFieldValue, userName:usernameFieldValue, token:token, sendEmail:sendEmailFieldValue, pathname: window.location.pathname, type: 'static' }));
      setSaveData((...prevState) => ({...prevState, onSave:true}));
      setShowMessageBar(prevCheck => ({prevCheck, open:true,msg:"Email sent", type:MessageBarType.success}));
    }

  } 

  const validateSave = ():boolean => {
    let valid = false;

      if (nameFieldValue === ""){
        setMessagebar("Name can't be empty ",MessageBarType.error);
        valid = false;
        return valid
      }
      
      if (validateEmail(emailFieldValue))
      {
        valid = true;
      }
      else{
        valid = false;
        setMessagebar('Email not valid',MessageBarType.error);
      }



      return valid;
  }

  const setMessagebar = (msg:string, type:MessageBarType) =>{
    setShowMessageBar(prevCheck => ({prevCheck, open:true,msg:msg, type:type}));
  }



  const copyLink = () => {
    if (myRef.current !== null){
      const el = myRef.current; 

      navigator.clipboard.writeText(el.innerHTML).then(
        () => {
          setMessagebar('Copired',MessageBarType.success);
        },
        () => {
          setMessagebar('Copy failed!',MessageBarType.success);
        }
      );

    }
  }

  // Udate fields
  React.useEffect(() => {
    setNameFieldValue(saveInput.name);
    setEmailFieldValue(saveInput.email);
    setPassFieldValue(saveInput.code || '');
    setUsernameFieldValue(saveInput.userName || '');
  
    if (myRef.current !== null && saveInput.code !== ''){
      const el = myRef.current;
      const fullUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + "?code=" + saveInput.code + "&type=static" || ''; 
      el.innerHTML = fullUrl;
    }
    // eslint-disable-next-line
  }, [saveInput]);


    const onCancel = () => {

      if (props.onCancel !== undefined){
        props.onCancel()
      }

    }

    const onChangeEmailCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
      if (isChecked !== undefined){
        setSendEmailFieldValue(isChecked);
      }
    }


    const onChangeFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

            switch (event.currentTarget.dataset.label) {
                case "name":
                    setNameFieldValue(newValue || '')
                  break;
              
                case "email":
                    setEmailFieldValue(newValue || '')
                  break;

                case "password":
                    setPassFieldValue(newValue || '')
                    break;

                case "username":
                    setUsernameFieldValue(newValue || '')
                    break;
      

                default:
                  console.log();
              }
        },
        [props],
    );

    return(
        <>
<Stack tokens={stackTokens}>

    <div style={{width:"100%", height:"20px"}}>  
      { showMessageBar?.open === true ?  
      <MessageBar messageBarType={showMessageBar.type} isMultiline={false} onDismiss={()=> setShowMessageBar(prevCheck => ({prevCheck, open:false}))} dismissButtonAriaLabel="Close" >
          {showMessageBar.msg}
      </MessageBar>
      : null}
    </div>

      <TextField
        label={langaugeConvert("Code", languageMap, false)}
        data-label="password"
        value={passFieldValue}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
        readOnly={true}
        disabled={true}
      />


      <TextField
        label={langaugeConvert("Name", languageMap, false)}
        data-label="name"
        value={nameFieldValue}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />

      <TextField
        label={langaugeConvert("My name", languageMap, false)}
        data-label="username"
        value={usernameFieldValue}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />

       <Checkbox label={langaugeConvert("Send E-mail", languageMap, false)} defaultChecked  onChange={onChangeEmailCheckbox} />

      <TextField
        label={langaugeConvert("E-mail", languageMap, false)}
        data-label="email"
        value={emailFieldValue}
        onChange={onChangeFieldValue}
        styles={textFieldStyles}
      />


      <div className="button-container">
        <ReCaptchaComponent title={langaugeConvert("Save", languageMap, true)} onSave={(token) => onSave(token)}/>
        <PrimaryButton text={langaugeConvert("Cancel", languageMap, true)} onClick={() => onCancel()} style={{width:"100px", marginLeft:"10px"}} />
      </div>

    </Stack>

   
    <FontIcon aria-label="Copy" iconName="Copy" className={iconClass} onClick={() => copyLink()}/>
    <div ref={myRef} style={{backgroundColor:"#ffdf91", width:"100%", height:"60px", padding:"5px"}}>
    </div>
    

   </>
    )
}