import React from "react";
import { Container, ContainerMobile, Main, SideBar } from './../views/MainLayout';
import { FontIcon } from "@fluentui/react/lib/Icon";
import { LeftPanel } from './../views/LeftPanel';
import { Content } from './../views/Content';
import { Panel, PanelType, IPanelProps, IRenderFunction, mergeStyles} from "@fluentui/react";
import { screenEventAtom } from "./../state/event";
import { useRecoilState } from "recoil";
import { IOType } from "child_process";

const iconClass = mergeStyles({
    fontSize: 30,
    height: 30,
    width: 30,
    color: "white",
    cursor: "pointer",
    marginTop: "10px",
    marginLeft: "8px"
  });


  export interface IProps{
    openLeftSideSettings: () => void;
  }

  

const CalculatorPage = (props: IProps) => {
    const [readScreenValue,] = useRecoilState(screenEventAtom);

    return(
      <>
      {readScreenValue.type === "w_xm" ?
      <>
        <ContainerMobile>
          <Main>
            <Content/>
          </Main>
        </ContainerMobile>
        <div className="fixed-nav-left" onClick={() => props.openLeftSideSettings()}>
            <FontIcon aria-label="Settings" iconName="settings" className={iconClass} />
        </div>
      </>
        :
      <Container>
        <SideBar>
            <LeftPanel />
        </SideBar>
        <Main>
          <Content/>
        </Main>
      </Container>
  }
    </>
  
  )
  }


  export default CalculatorPage;