import React, { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { IlanguageText, languageMap, ILanguageMap } from "../state/language";


export const useLanguage = ():[ILanguageMap] => {
  const [language, setlanguage] = useRecoilState(languageMap);

  //const langConvert = useCallback(() => {
  //},[]);

    return [language] ;
};