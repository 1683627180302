import React from "react";
import {Slider, Stack } from "@fluentui/react";
import {SliderValueFormat} from "../util";
import { useRecoilState } from "recoil";
import { inputDataAtom } from "../state/input";
import { colorStyles } from "./Config";
import { cutDecimal, langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";


export function ProcessShare() {
    const [languageMap] = useLanguage();
    const  [inputData,setInputData] = useRecoilState(inputDataAtom)

    const onChangeLightProcess = (newLightProcess:number) => {setInputData(v=>({
        ...v,
        lightProcess:newLightProcess,
        heavyProcess:100-newLightProcess
    }))};

    const onChangeHeavyProcess = (newHeavyProcess:number) => {setInputData(v=>({
        ...v,
        heavyProcess:newHeavyProcess,
        lightProcess:100-newHeavyProcess
    }))};


    return (
        <Stack>
            <Slider styles={colorStyles} label={langaugeConvert("Normal load (85kg)/(187lbs)", languageMap, false)} min={0} max={100} showValue 
            valueFormat={SliderValueFormat} 
            onChange={onChangeLightProcess} 
            value={inputData.lightProcess}/>

            <Slider styles={colorStyles} label={langaugeConvert("Heavy load (150kg)/(330lbs)", languageMap, false)}  min={0} max={100} showValue 
            valueFormat={SliderValueFormat} 
            onChange={onChangeHeavyProcess} 
            value={inputData.heavyProcess}/>
        </Stack>
    );
}
