import React from "react";

export interface IProps{
    text: string;
    id?: string;
}

const Heading = (prop:IProps) =>{
return(
    <div key={prop.id} className="left-menu-header">    
        {prop.text}
    </div>
);

}

export default Heading;