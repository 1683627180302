import {useState, useEffect, useCallback} from "react";
import { IHoistDataItem } from "./state/hoist";
import { InputCalculated } from "./views/Calculated";
import { ILanguageMap } from "./state/language";
import { IMenu } from "./state/menu";
import Cookies from 'universal-cookie';
import axios from "axios";


export type fetchState = "IDLE" | "SUCCESS" | "ERROR" | "PENDING";
export type fetchPath = "File" | "API";
export type methods = "GET" | "POST";
export type empty = boolean;


export interface IFetctResponseError{
  message: string;
}

export interface ICutDecimal {
  isDecimal: boolean;
  int: string;
  dec:string;
  canCalculate:boolean;
}


export const SliderValueFormat = (value: number) => `${value}%`;

export const MyFormatNumber = (value: number) => {return Intl.NumberFormat(window.navigator.language).format(Math.ceil(value))};


export const formatTextfieldTypeCalStandard = () :boolean => {
  const calValue = Intl.NumberFormat(window.navigator.language).format(parseFloat("1000"));
  
  if (calValue.includes(".")){
    return true;
  }
  else
  {
    return false;
  }
}

export const cutDecimal = (value:string):ICutDecimal =>{

  if (value.slice(-2).replace(",",".").includes(".0")){
    let currentValue = ClearNumber(value.slice(0,-2)) + value.slice(-2).replace(",",".");  
    let arr = currentValue.split(".");
    return {isDecimal:true, int:ClearNumber(arr[0]), dec:"0", canCalculate:false}
  }
  else if (value.trim() === ""){
    return {isDecimal:false, int:"", dec:"", canCalculate:false}
  }
  else if (value.slice(-1).replace(",",".").includes(".")){
    let currentValue = ClearNumber(value.slice(0,-1)) + value.slice(-1).replace(",",".");  
    let arr = currentValue.split(".");
    return {isDecimal:true, int:ClearNumber(arr[0]), dec:"", canCalculate:false}
  }
  else if (isDecimal(value.slice(-2)))
  {
    let currentValue = ClearNumber(value.slice(0,-2)) + value.slice(-2).replace(",",".");  
    let arr = currentValue.split(".");    
    return {isDecimal:true, int:ClearNumber(arr[0]), dec:arr[1], canCalculate:true}
  }
  else if (isDecimal(value.slice(-3)))  
  {
    let currentValue = ClearNumber(value.slice(0,-3)) + value.slice(-3).replace(",",".");  
    let arr = currentValue.split(".");    
    return {isDecimal:true, int:ClearNumber(arr[0]), dec:arr[1], canCalculate:true}
  }
  else if (isNaN(value as any)){
    let resetValue = ClearNumber(value.slice(0,-1)) + value.slice(-1).replace(",",".")
    return {isDecimal:false, int:resetValue, dec:"", canCalculate:false}
  }
  else{
    return {isDecimal:false, int:ClearNumber(value), dec:"", canCalculate:true}
  }
}


const isDecimal = (value:string):boolean => {
  let retValue = false;
  let currentValue = "1" + value.replace(",",".");
  var convertNumber = parseFloat(currentValue);

  if (!Number.isInteger(convertNumber))
  {
    retValue = true;
  }

  return retValue;
}




export const TextFieldFormatNumber = (value: string):string => {
  const options2 = { maximumFractionDigits: 2};
//  let cleanValue =  ClearNumber(value);  
  let retValue = "";
  let currentValues = cutDecimal(value);

  if (currentValues.isDecimal === false){
      // console.log(currentValues.int + " >>False<<");
       retValue = currentValues.int      
  }
  else{
       retValue =  currentValues.int + "." + currentValues.dec;
      // console.log(currentValues.int + "." + currentValues.dec + " >>True<<");
  }

  if (currentValues.canCalculate === true)
  {
    retValue = Intl.NumberFormat(window.navigator.language,options2).format(parseFloat(retValue));
  }



//  if (cleanValue != "" && !isNaN(cleanValue as any) && checkLastDot(value) === false){
//  console.log(value);
//  retValue = Intl.NumberFormat(window.navigator.language,options2).format(parseFloat(value.replace(/,/, '.')));
//  }
//  else
//  {
//    console.log(value);
//    retValue = value;
//  }



  return retValue
};



export const TextFieldFormatNumber_old = (value: string):string => {

  let cleanValue =  ClearNumber(value);  
  let retValue = "";
  //check if value
  if (cleanValue != "" && !isNaN(cleanValue as any)){
    retValue = Intl.NumberFormat(window.navigator.language).format(Math.ceil(parseInt(cleanValue)))
  }
  else
  {
    retValue = value;
  }
  return retValue
};







export const checkLastDot = (value:string):boolean => {
  if (value.slice(-1) === "." || value.slice(-1) === ","){
    return true;
  }
  else
  {
    return false;
  }
}


export const displayLocaleFormatDigt = (value: string):string => {
  const options2 = { maximumFractionDigits: 2};
  let cleanValue =  ClearNumber(value);  
  let retValue = "";
  //check if value
  if (cleanValue != "" && !isNaN(value as any) && checkLastDot(value) === false ){
    retValue = Intl.NumberFormat(window.navigator.language,options2).format(parseFloat(value));
  }
  else
  {
    retValue = value;
  }
  return retValue
};



export function YearlyProcesses(value:InputCalculated)
{
  return(
        Math.ceil((value.noOfHoists*(value.utilization/100))*parseInt(ClearNumber(value.processesDay))*365)
    );
};

export const ClearNumber = (value:string):string => {
  return(value.replaceAll(".","").replaceAll(",",""));
}

export function TimeSpend(hoistData:IHoistDataItem, paramValue:InputCalculated)
{
    return(
        ((YearlyProcesses(paramValue)*paramValue.lightProcess/100)*hoistData.timeNormalLoad +
        (YearlyProcesses(paramValue)*paramValue.heavyProcess/100)*hoistData.timeHeavyLoad)/3600
    );
};

export function CostYear(hoistData:IHoistDataItem, paramValue:InputCalculated) {
    return(
        TimeSpend(hoistData,paramValue)*parseInt(paramValue.costHour)
    );
};

export const SaveCookie = (jsonStr:string,name:string) => {
    const cookies = new Cookies();
    cookies.set(name, jsonStr, { path: '/' });
}

export const LoadCookie = (name:string):string => {
    const cookies = new Cookies();
    return(cookies.get('calData'));
}

export const getRandomCode = (length:number) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

export const validateEmail = (email:string) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
}

export const langaugeConvert = (keyText:string, languageMap: ILanguageMap, uppercase:boolean, menuObj?:IMenu[]):string => {
  let langStr = "";
  const currentPath = window.location.pathname.toLowerCase();

  if (menuObj !== undefined)
  {
    //console.log(currentPath);
    let currentId = menuObj.find(x => x.menuTo.toLowerCase() === currentPath);
    //console.log(currentId?.menuComponentId + "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    
    if (currentId?.menuComponentId === null)
    {
      keyText = keyText + "_" + "0"
    }
    else
    {
      keyText = keyText + "_" + currentId?.menuComponentId;

    }
  }


  if (languageMap.languageMap !== undefined){
    if (languageMap.languageMap.get(keyText) !== undefined)
    {
      langStr = languageMap.languageMap.get(keyText)!.languageText;
    }
  }

  if (langStr === "")
  {
    langStr = keyText;
  }


  return uppercase === true ? langStr.toUpperCase() : langStr;
}

//T = response, V = request paramaters
export const Fetch = <T,V> (methode:methods, endpoint:string, request?:V, multipart:boolean = false) : [fetchState, T | undefined, IFetctResponseError | undefined, (val?:V) => void] => {
    const [state, setState] = useState<fetchState>("IDLE");
    const [responseData, setResponseData] = useState<T>(); 
    const [responseError, setResponseError] = useState<IFetctResponseError>(); 
    const [req, setReq] = useState<V | undefined>(undefined); 
   
    const setHandleStatus = useCallback((status:fetchState) => {
      setState(status);
    }, []);
  
  
    const setRequest = useCallback((val?: V) => {
      setReq(val);
     },[]);
  
  
    const config = {
      withCredentials: true,
      headers: {'content-type': 'application/json'},
      params: request,
      };
    
  
  //headers: config
  
    useEffect(() => {
      let response:any;
      const fetchData = async () => {
      try{
            setHandleStatus("PENDING");
            if (methode === "GET"){
              config.params = request !== undefined ? request : req;
              response = await axios.get( endpoint , config);
            }
            else{

              if(multipart){
                config.headers["content-type"] = 'multipart/form-data';
              }

              response = await axios.post( endpoint , request !== undefined ? request : req , config );
            } 
            
            //console.log(response.data as T)
            setResponseData(response.data as T)
            setHandleStatus("SUCCESS");
          }
      catch(error:any)
          {
            setResponseError(error.response.data as IFetctResponseError); 
            console.log(error.response.data as IFetctResponseError);
            setHandleStatus("ERROR");
          }
          setRequest(undefined);
       }
  
      if (req !== undefined || request !== undefined )
       {
        fetchData();
      }
    // eslint-disable-next-line
    }, [req]);
  
    return [state, responseData, responseError, setRequest]
  }