import { atom } from "recoil";
import { InputCalculated } from "../views/Calculated";

export const inputDataAtom = atom<InputCalculated>({
    key: "inputData",
    default: {
        processesDay: "0",
        noOfHoists: 0,
        utilization: 0,
        lightProcess: 0,
        heavyProcess: 0,
        costHour: "0"
    }
});
