import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Overview } from './../views/OverviewPage';
import { DynamicCalculate } from './../views/DynamicCalculate';
import CalculatorPage from './../views/CalculatorPage';
import { menuAtom } from "../state/menu";
import { useRecoilState } from "recoil";



export interface IPropsLinkLoader{
    openLeftSideSettings: () => void;
    name: string;
    dataId: string;
}


const LinksCustomComponentLoader = (props: IPropsLinkLoader) =>{

    if  (props.name === "page1" )
    {
        return (<Overview/>);
    }
    else if  (props.name === "page2" )
    {
        return (<CalculatorPage openLeftSideSettings={() => props.openLeftSideSettings()} />);
    }
    else
    {
        return (<DynamicCalculate siteId={parseInt(props.dataId)}/>);
    }
}



export interface IProps{
    openLeftSideSettings: () => void;
}



const LinksCustom = (props:IProps) =>{
    const [menuData, setmenuData] = useRecoilState(menuAtom);


return(
        <Routes>
                    {
                menuData.map((item,i)=> 
                (
                    <Route key={i} path={item.menuTo} element={<LinksCustomComponentLoader name={item.menuComponentName} openLeftSideSettings={() => props.openLeftSideSettings()} dataId={item.menuComponentId}/>}/>
                ))

}
        </Routes>
)}

export default LinksCustom;