import { Stack } from "@fluentui/react";
import { Calculated } from "./Calculated";
import { TimeUsedTable } from "./TimeUsedTable";
import { hoistsDataAtom } from "../state/hoist";
import { useRecoilState } from "recoil";
import { EditCalculatedPage } from "../components/Cards/EditCalculatedPage";


export const Content = () =>
{
    const  [hoistData] = useRecoilState(hoistsDataAtom);

    return (
        <Stack>
            <EditCalculatedPage data={hoistData}/>
            {/*<TimeUsedTable data={hoistData} />
            <Calculated data={hoistData} />*/}
        </Stack>
    )
}