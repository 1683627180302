import { Stack } from "@fluentui/react";

import { IHoistDataItem } from "../../state/hoist";
import { InputCalculated } from "../../views/Calculated";
import { CostYear, MyFormatNumber, TimeSpend, displayLocaleFormatDigt, langaugeConvert} from "../../util";
import { userMode } from "../../state/saveInput";
import { useLanguage } from "./../../components/useLanguage";


interface Props2
{
    data: IHoistDataItem;
    input: InputCalculated;
    onClick?: () => void;
    editMode: userMode;
}



const CardHoistsItem = (props: Props2) => {
    const { data, input } = props;
    const [languageMap] = useLanguage();

    const onClick = () =>{
            props.onClick!();
    }


    const getCurrentImg_ORG = ():string => {
        let imgUrl = "";
        const uploadPic = window.location.origin as string + process.env.REACT_APP_API_GETPIC_PATH as string;

        if (!props.data.img?.includes("http://") && !props.data.img?.includes("https://")){
            imgUrl = uploadPic + "?id=" + props.data.img;
        } 
        else
        {
            imgUrl = props.data.img;
        }
        return imgUrl;
    }

    const getCurrentImg = () => {
        let imgUrl = "";
        const uploadPic = window.location.origin as string + process.env.REACT_APP_API_GETPIC_PATH as string;

        if (!props.data.img?.includes("http://") && !props.data.img?.includes("https://")){
            imgUrl = uploadPic + "?id=" + props.data.img;
        } 
        else
        {
            imgUrl = props.data.img;
        }
        
        if (props.data.img !== "" && props.data.showImg)
        {
            return <img className="img-fit" src={imgUrl} />
        }
        else
        {
            return null    
        }    

    }


 return(
    <div className="card"  onClick={() => onClick()}>
        <div className="header">{data.name}</div>
        <div className="container">
            <div className="split">
                <div className="header-item">{langaugeConvert("Time used per process", languageMap, false)}</div>
                <div className="item-container">
                    <div className="item">{langaugeConvert("Normal load", languageMap, false)}</div>
                    <div className="item text-right">{displayLocaleFormatDigt(data.timeNormalLoad.toString())} sec</div>
                </div>
                <div className="item-container">
                    <div className="item">{langaugeConvert("Heavy load", languageMap, false)}</div>
                    <div className="item text-right">{displayLocaleFormatDigt(data.timeHeavyLoad.toString())} sec</div>
                </div>
                <div className="header-item">{langaugeConvert("Total used", languageMap, false)}</div>
                <div className="item-container">
                    <div className="item ">{langaugeConvert("Hours", languageMap, false)}</div>
                    <div className="item text-right">{MyFormatNumber(TimeSpend(data,props.input))}</div>
                </div>
                <div className="item-container">
                    <div className="item">{langaugeConvert("Cost", languageMap, false)}</div>
                    <div className="item text-right">{MyFormatNumber(CostYear(data,props.input))}</div>
                </div>
            </div>
            <div className="bottom">{getCurrentImg()}</div>
        </div>
    </div>
)

}

interface Props
{
    data: IHoistDataItem[];
    input: InputCalculated;
    onClick?: (id:string) => void;
    editMode: userMode;
}

export const CardsHoists = (props: Props) =>
{
    const { data, input } = props;
 
    const onClick = (id:string) =>{
            props.onClick!(id);
    }


    return (
           <div className="grid">
            {
                props.data.map((item, i) => (
                    <CardHoistsItem key={i} data={item} input={input} onClick={() => onClick(item.id)} editMode={props.editMode}/>
                ),[data])
            }
            </div>
    );
}