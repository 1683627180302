import React, {useEffect, useState, useCallback} from "react";
import { Stack, mergeStyles, Panel,PanelType } from "@fluentui/react";
import { useRecoilState } from "recoil";
import {DynamicElements, IElements, IResult, ISettings as IElementSetting} from "./../components/DynamicElements";
import TemplateResult from "./../components/TemplateResult";
import { SideBar } from './../views/MainLayout';
import { screenEventAtom } from "../state/event";
import { FontIcon } from "@fluentui/react/lib/Icon";


interface IProps {
  siteId: number;
}

const iconClass = mergeStyles({
  fontSize: 30,
  height: 30,
  width: 30,
  color: "white",
  cursor: "pointer",
  marginTop: "10px",
  marginLeft: "8px"
});

export const DynamicCalculate = (prop: IProps) =>
{
  const [results, setResults] = React.useState<IResult[]>();
  const [elements, setElements] = React.useState<IElements[]>();
  const [setting, setSetting] = React.useState<IElementSetting>();
  const [readScreenValue,] = useRecoilState(screenEventAtom);
  const [isOpenSettings, setIsOpenSettings] = useState(true);
  const [autoClose, setAutoClose] = React.useState<boolean>(true);
  const path = {path:'Test'}
  
  const openLeftSideSettings = useCallback(() => {
    setIsOpenSettings(true);
    setAutoClose(false)
  }, []);

  const closeLeftSideSettings = useCallback(() => {
     setIsOpenSettings(false)
  }, []);

  
  const onChange = (elements: IElements[], results: IResult[]) =>{
    setResults(results);
    setElements(elements);
  }
  
  const onSetting = (settings: IElementSetting) =>{
    setSetting(settings)
}

const onLoaded = () =>{
  if (autoClose)
  {
    setIsOpenSettings(false);
    setAutoClose(true);
  }
}

  return (
    <>
    {
    readScreenValue.type === "w_xm" ? 
      <>
      <div style={{display:"flex"}}>
        <div style={{marginLeft:"50px", marginTop:"10px"}}>
          <Stack>
            <TemplateResult result={results!} elements={elements!} setting={setting!} templateName={setting?.templateName} siteId={prop.siteId}/>
          </Stack>
        </div>
      </div>
      <div className="fixed-nav-left" onClick={openLeftSideSettings}>
        <FontIcon aria-label="Settings" iconName="settings" className={iconClass} />
      </div>

      <Panel id="override-panel" isOpen={isOpenSettings} onDismiss={() => closeLeftSideSettings()} type={PanelType.smallFixedNear}  >
      <SideBar>
        <Stack>
          <DynamicElements siteId={prop.siteId} onChange={(elements, results) => onChange(elements,results)} onSettings={(settings) => onSetting(settings)} onLoaded={() => onLoaded()}/>
        </Stack>
      </SideBar>
    </Panel>

      </>
    :
      <div style={{display:"flex"}}>
        <div style={{width:"300px"}}>
          <SideBar>
            <Stack>
              <DynamicElements siteId={prop.siteId} onChange={(elements, results) => onChange(elements,results)} onSettings={(settings) => onSetting(settings)} onLoaded={() => onLoaded()} />
            </Stack>
          </SideBar>
        </div>
        <div style={{marginLeft:"10px", marginTop:"10px"}}>
          <Stack>
            <TemplateResult result={results!} elements={elements!} templateName={setting?.templateName} siteId={prop.siteId} setting={setting!}/>
          </Stack>
        </div>
      </div>
  }

</>       

    )
}
