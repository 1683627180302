import { atom } from "recoil";

export type userMode = "Edit" | "View";

export interface ISavedConfig
{
   id?: number;
   name: string;
   email: string;
   code?: string;
   configData?: string;
   password?: string;
   userName?: string;
   token?: string;
   sendEmail: boolean;
   pathname: string;
   type: string;
}

export interface IEditMode
{
   editMode: userMode;
}

export const savedinputConfig = atom<ISavedConfig>({
    key: "SavedInputConfig",
    default: {id:0, name:'',email:'', code:'', configData:'', userName:'', token:'', sendEmail:true, pathname:'', type:'static'}
});

export const savedinputConfigDynamic = atom<ISavedConfig>({
    key: "SavedInputConfigDynamic",
    default: {id:0, name:'',email:'', code:'', configData:'', userName:'', token:'', sendEmail:true, pathname:'', type:'dynamic'}
});



export const editorMode = atom<IEditMode>({
    key: "EditorMode",
    default: {editMode:"View"}
});



