import {Stack, TextField } from "@fluentui/react";
import { inputDataAtom } from "../state/input";
import { useRecoilState } from "recoil";
import {MyFormatNumber, YearlyProcesses, TextFieldFormatNumber} from "../util";
import { colorTextFieldStyles } from "./Config";
import { langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";


export function ProcesseesDay() {
    const [languageMap] = useLanguage();
    const  [inputData, setInputData] = useRecoilState(inputDataAtom)

    // const [processes,setProcesses] = useState(10);
    // const onProccessesChanged = (_:any,newValue:string|undefined)=> {setProcesses(parseInt( newValue??"0"))};

    // _ does not matter, we are not using it, without this argument the onChange event is giving an error
    const onProccessesChanged = (_:any,newProcesses:string|undefined) => {
        setInputData(v=>({...v,processesDay: newProcesses??"0"}))
    };

    return (
        <Stack>
            <TextField styles={colorTextFieldStyles} label={langaugeConvert("Processes per day", languageMap, false)}  
                value={ TextFieldFormatNumber(inputData.processesDay) }
                onChange={onProccessesChanged} />
            
            <TextField styles={colorTextFieldStyles} label={langaugeConvert("Processes per year (Calculated)", languageMap, false)} readOnly value={MyFormatNumber(YearlyProcesses(inputData)).toString()}/>
        </Stack>
    );
}
