import { ISettings } from "@fluentui/react";
import React from "react";
import {DynamicElements, IElements, IResult, ISettings as IElementSetting} from "./../components/DynamicElements";


export interface IPropsTemplateResult {
   templateName?:string;
   result:IResult[];
   elements:IElements[];
   siteId: number;
   setting: IElementSetting;
 }




const TemplateResult = (props:IPropsTemplateResult) => {

    const Template = React.useMemo(() => { const ret =  React.lazy(() =>  import('./../components/Cards/' + props.templateName)  ); return ret},[props.templateName])  

    return(<>
     {
    props.templateName !== undefined ?
    <React.Suspense fallback={<div>Loading...</div>}>
        <Template result={props.result} elements={props.elements} templateName={props.templateName} setting={props.setting} />
    </React.Suspense>
    :null
    }  
    
    </>)

  };

  export default TemplateResult;