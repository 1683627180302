import { atom } from "recoil";

export interface IlanguageText
{
    languageCode: string;
    languageDefault: string;
    languageText: string;
}

export interface ILanguageMap {
    languageMap? : Map<string,IlanguageText>;
}


export const languageMap = atom<ILanguageMap>({
    key: "LanguageText",
    default: {languageMap:undefined}
});


export const languageSet = atom<string>({
    key: "LanguageSet",
    default: "fr"
});