import { atom } from "recoil";

export interface IHoistDataItem
{
    id: string;
    name: string;
    timeNormalLoad: number;
    timeHeavyLoad: number;
    img?: string;
    imgId?: string;
    showImg: boolean;
}

export const hoistsDataAtom = atom<IHoistDataItem[]>({
    key: "HoistDataItem",
    default: []
});
