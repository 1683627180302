import React from "react";
import { Stack } from "@fluentui/react";
import { Hoists } from "../components/Hoists";
import { HoistUtilization } from "../components/HoistUtilization";
import {ProcessShare} from "../components/ProcessShare";
import { ProcesseesDay } from "../components/ProcessesDay";
import { CostHour } from "../components/CostHour";
import { langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";

// interface Props
// {
//     value: InputCalculated;
//     onChange: (newValue: InputCalculated) => void;
// }

export function LeftPanel() {
    const [languageMap] = useLanguage();

    return (
        <Stack>
            <div className="left-menu-header">    
                {langaugeConvert("Scenario", languageMap, false)}
            </div>
            <Hoists/>
            <HoistUtilization/>
            <ProcesseesDay/>
            <ProcessShare/>
            <CostHour/>
        </Stack>
    );
}
