import { atom } from "recoil";


import {IElements, IResult, ISettings} from "./../components/DynamicElements";


export interface IElementsDataAtom
{
    siteId: number; 
    elements : IElements[];
    results : IResult[]
    settings : ISettings;
}


export const elementsAtom = atom<IElementsDataAtom[]>({
    key: "ElementsAtom",
    default: []
});