import React, { useCallback, useState } from "react";
import { Container, ContainerMobile, Main, SideBar } from './views/MainLayout';
import { LeftPanel } from './views/LeftPanel';
import { Overview } from './views/OverviewPage';
import { DynamicCalculate } from './views/DynamicCalculate';
import { Config } from './components/Config';
import { Header } from './views/Header'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Panel, PanelType, IPanelProps, IRenderFunction, mergeStyles} from "@fluentui/react";
import { Menu } from './components/Menu';
import { UseViewport } from "./components/useViewportState";
import LinksCustom from './components/Links'



function App() {
  const [readComp, setComp] = React.useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  

  const openLeftSideMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const openLeftSideSettings = useCallback(() => {
    setIsOpenSettings(true);
  }, []);

  const closeLeftSideSettings = useCallback(() => {
    setIsOpenSettings(false)
  }, []);


  const closeLeftSideMenu = useCallback(() => {
    setIsOpen(false)
  }, []);


  const onRenderNavigationContent: IRenderFunction<IPanelProps> = React.useCallback((props, defaultRender) => (
      <>
        <div style={{width:"100%", marginLeft:"15px", marginTop:"10px"}}>
          <a style={{borderStyle:"none"}} href="https://www.guldmann.com"><img className="mobileLogo" loading="lazy" src="https://www.guldmann.com/dist/images/logo-Guldmann.png" alt="Guldmann"/></a>
        </div>
        
        {
          // This custom navigation still renders the close button (defaultRender).
          // If you don't use defaultRender, be sure to provide some other way to close the panel.
          defaultRender!(props)
        }
      </>
    ),
    [],
  );



  
  return (
    <Config>
      <UseViewport>
        <BrowserRouter>
        <Header openLeftSideMenu={() => openLeftSideMenu()}/>
        
          <LinksCustom openLeftSideSettings={() => openLeftSideSettings()}/>
     
        <Panel id="override-panel" isOpen={isOpen} onDismiss={() => closeLeftSideMenu()} type={PanelType.smallFixedNear} onRenderNavigationContent={onRenderNavigationContent} >
            <div style={{width:"100%", borderTop:"2px solid #FDB913"}} onClick={() => setIsOpen(false)}>
              <Menu stylename="header-main-navigation-mobile"/>
            </div>
        </Panel>

        <Panel id="override-panel" isOpen={isOpenSettings} onDismiss={() => closeLeftSideSettings()} type={PanelType.smallFixedNear} onRenderNavigationContent={onRenderNavigationContent} >
              <SideBar>
                  {readComp !== undefined ? readComp : null}
                  <LeftPanel />
              </SideBar>
        </Panel>


        </BrowserRouter>
      </UseViewport>      
    </Config>
        
  );
}

export default App;
