import { TextField } from "@fluentui/react";
import { useRecoilState } from "recoil";
import { inputDataAtom } from "../state/input";
import { colorTextFieldStyles } from "./Config";
import { TextFieldFormatNumber, ClearNumber} from "../util";
import { cutDecimal, langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";

export function CostHour() {
    const  [inputData, setInputData] = useRecoilState(inputDataAtom)
    const [languageMap] = useLanguage();

    const onCostChanged = (_:any,newCost:string|undefined) => {setInputData(v=>({
        ...v,
        costHour: ClearNumber(newCost!)??"0"
    }))};
    return(
        <TextField styles={colorTextFieldStyles} label={langaugeConvert("Cost per hour", languageMap, false)} value={TextFieldFormatNumber(inputData.costHour)} onChange={onCostChanged}/>
    );
}