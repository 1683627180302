import { useRecoilValue, useRecoilState } from "recoil";
import { langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";
import ReactHtmlParser from 'html-react-parser';
import { decode } from 'js-base64';
import { menuAtom } from "../state/menu";

const headText = (htmloutput:string):string => {
  let outPutHtml = decode(htmloutput);
  return outPutHtml;
}


export const Overview = () => {
  const [languageMap] = useLanguage();
  const [menuData, setmenuData] = useRecoilState(menuAtom);

    return(
      <>
      <div className='head-text-container'> 
        <div className='head-text'>
        <h1>{ReactHtmlParser(headText(langaugeConvert("htmlHeadTextFront", languageMap, false, menuData)))}</h1>
        </div>
      </div>
      <div className="center-content">
        <div className="content-container">
        <div className="spit">
        {ReactHtmlParser(headText(langaugeConvert("htmlBodyTextFront", languageMap, false, menuData)))}
        </div>
      </div>
      </div>
     </>
    )
}