import React, {useEffect} from "react";
import { menuAtom } from "../state/menu";
import { useRecoilState } from "recoil";
import { Link } from 'react-router-dom';
import { setUnderline } from "../state/event";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { cutDecimal, langaugeConvert, Fetch } from "../util";
import { useLanguage } from "../components/useLanguage";
import { Dropdown, IDropdownStyles, IDropdownOption, DropdownMenuItemType} from "@fluentui/react";
import { IlanguageText, languageMap } from "../state/language";


interface Props
{
    stylename: string;
}

interface ILanguageRequest {
    lang: string;
  }

const options: IDropdownOption[] = [
    { key: 'Header', text: 'Language', itemType: DropdownMenuItemType.Header },
    { key: 'en', text: 'English' },
    { key: 'fr', text: 'France' },
    { key: 'de', text: 'German' },
  ];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 100 },
  };

  export const languageConfig = () => window.location.origin as string + "/api/Ttc/GetLanguage" as string;


export const Menu = (props:Props) => {
    const [menuData, setmenuData] = useRecoilState(menuAtom);
    const [underline, setUnderling] = useRecoilState(setUnderline);
    const location = useLocation(); 
    const currentPath = location.pathname.replace('/','').toLowerCase();
    const TRACKING_ID = "G-0803LSF4YW";
    const [languageMap] = useLanguage();
    const [statelanguage, responseLanguage, responseErrorLanguage, LanguageConfigRequest] = Fetch<IlanguageText[], ILanguageRequest>("GET",languageConfig());
    //const [language, setlanguage] = useRecoilState(languageMap);
    //const [lang,setlang] = useState<string>("en");

    const onChangeHandle = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        LanguageConfigRequest({lang:option!.key as string});
    }

    ReactGA.initialize(TRACKING_ID)


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }, [currentPath]);



return(
    <>
    <ul className={props.stylename}>
        {
        menuData.map((item,i)=> 
            (
            currentPath === item.menuTo.toLowerCase().replace('/', '') || (currentPath === '' && i === 0) ? 
            <li className="set-underling" key={i}  >
                <Link  to={item.menuTo}>{langaugeConvert(item.menuName, languageMap, false)}</Link>
            </li>
            :
            <li key={i}  >
                <Link  to={item.menuTo}>{langaugeConvert(item.menuName, languageMap, false)}</Link>
            </li>

            )
        ,[underline.path])
        }
    </ul>
    </>
)


}