import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Menu } from '../components/Menu';
import { Dropdown, IDropdownStyles, IDropdownOption, DropdownMenuItemType} from "@fluentui/react";
import { Fetch, empty, getRandomCode } from "./../util";
import { IlanguageText, languageMap } from "../state/language";
import { Console } from "console";

interface Props
{
    openLeftSideMenu?: () => void;
}

export const languageConfig = () => window.location.origin as string + "/api/Ttc/GetLanguage" as string;


const options: IDropdownOption[] = [
    { key: 'Header', text: 'Language', itemType: DropdownMenuItemType.Header },
    { key: 'en', text: 'English' },
    { key: 'fr', text: 'France' },
    { key: 'de', text: 'German' },
  ];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 100 },
  };

  interface ILanguageRequest {
    lang: string;
  }


export function Header(prop:Props) {
    const [statelanguage, responseLanguage, responseErrorLanguage, LanguageConfigRequest] = Fetch<IlanguageText[], ILanguageRequest>("GET",languageConfig());
    const [language, setlanguage] = useRecoilState(languageMap);
    const [lang,setlang] = useState<string>("en");

    const queryParams = new URLSearchParams(window.location.search)

    let lan = queryParams.get("lang")
      
    if (lan === null)
    {
      lan = "en"
    }


    const onChangeHandle = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => {
        LanguageConfigRequest({lang:option!.key as string});
    }


    //Init language
    React.useEffect(() => {

        if (statelanguage === "SUCCESS")
        {
          if (responseLanguage !== undefined){
            let MapData = new Map<string,IlanguageText>();
  
            for (let i=0;i<responseLanguage.length;i++){
              MapData.set(responseLanguage[i].languageDefault,responseLanguage[i]); 
            }   
            setlanguage({languageMap:MapData});
          }
        }
  
        if (statelanguage === "ERROR"){
          console.log("Error Fetch Language Data")
        }
      // eslint-disable-next-line
      }, [statelanguage]);



return (
<>
<div className="choose-language-container">
    <div className="choose-language">
    <Dropdown
        placeholder="Select an option"
        defaultSelectedKey={lan}
        options={options}
        disabled={false}
        styles={dropdownStyles}
        onChange={onChangeHandle}
      />
    </div>
</div>
<div className="header-main">
        <div className="header-main-container" >
            <div className="header-container-img main-logo"><a  style={{float: "right", marginLeft:"5px"}} href="https://www.guldmann.com" ><img loading="lazy" src="https://www.guldmann.com/dist/images/logo-Guldmann.png" alt="Guldmann"/></a></div>
                <div style={{width:"79%"}} className="hide-topmenu">
                    <Menu stylename="header-main-navigation"/>
                </div>
                <div style={{width:"100%"}} className="hide-topmenu-icon">
                    <ul style={{float:"right", marginRight:"50px"}} className="header-main-navigation">
                        <li onClick={() => prop.openLeftSideMenu!()}>
                            <svg className="menu-icon">
                                <use xlinkHref="icons.svg#burger-menu"></use>
                            </svg> 
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
);
}