import React from "react";


export interface IProps{
    height:number;
    id?:string;
}


const Space = (prop:IProps) =>{
return(<>

    <div key={prop.id} style={{width:"100%", height:prop.height}}></div>

</>);

}

export default Space;