import React, {useEffect} from "react";
//import { Fetch, empty, getRandomCode, langaugeConvert } from "./../util";
import * as fluentui from "@fluentui/react";
import { colorStyles } from "./../components/Config";
import { colorTextFieldStyles } from "./../components/Config";
import Space from "./../components/Space";
import Heading from "./../components/Heading";
import { savedinputConfig, ISavedConfig, editorMode, savedinputConfigDynamic } from "../state/saveInput";
import { IElementsDataAtom, elementsAtom } from "../state/Elements";
import { useRecoilState } from "recoil";
import { Fetch, TextFieldFormatNumber, cutDecimal, langaugeConvert } from "../util";
import { useLanguage } from "../components/useLanguage";

export const getElements = () => window.location.origin as string + process.env.REACT_APP_API_GETELEMENTS_PATH as string;
export const loadConfig = () => window.location.origin as string + process.env.REACT_APP_API_LOAD_PATH as string;

type elementtypes = "textbox" | "slider" | "space" | "heading"

export interface IElements {
  id: string;
  elementType : elementtypes;
  labelName : string;
  readonly?: boolean;
  value?: string;
  min?: string;
  max?: string;
  bind?: string;
  fixed?: string;
}

export interface IResult {
  id: string;
  labelName : string;
  value?: string;
  bind?: string;
  bind2?: string;
  fixed?: string;
}

export interface ISettings {
  templateName: string;
  htmlHeadText: string;
  htmlBodyText: string;
}


export interface ElementsData {
    id : number;
    data : string;
}

interface ILoadConfigRequest {
  email: string;
  code: string;
}

export interface IConfig {
  elements : IElements[];
  results : IResult[]
  settings : ISettings;
}

export interface IRequestConfig {
  id: number;
}



export interface props{
  siteId: number;
  onChange: (elements:IElements[], results:IResult[]) => void;
  onSettings: (settings:ISettings) => void;
  onLoaded: () => void;
}


export const DynamicElements = (prop:props) =>
{

    const [stateMenu, responseMenu, ,defaultFn] = Fetch<ElementsData[],IRequestConfig>("GET", getElements());
    const [stateLoad, responseLoad, responseErrorLoad, loadConfigRequest] = Fetch<ISavedConfig[], ILoadConfigRequest>("GET",loadConfig());
    const [elements, setElements] = React.useState<IElements[]>();
    const [results, setResults] = React.useState<IResult[]>();
    const [settings, setSettings] = React.useState<ISettings>();
    const onTimeOnly = React.useRef<boolean>(true);
    const onTimeOnlyBindList = React.useRef<number[]>([]);

    const queryParams = new URLSearchParams(window.location.search)
    const [readElementsdata, setElementsdata] = useRecoilState(elementsAtom);
    const [saveInput, setSaveInput] = useRecoilState(savedinputConfigDynamic);
    const [languageMap] = useLanguage();


    const changeElementValue = (id:string, value:string) => {
    if (elements != undefined){
      let newArr = [...elements];
      
      elements.find(x=> x.id === id)!.value = value ;
      setElements(newArr);

      binding();
    }
  }

  const findBindElements = () => {

    elements!.forEach((item) => {

      if (item.bind !== undefined)
      {
        let newArr = [...elements!];

        let values = item.bind.split('=');
        let index = values[0].replaceAll('[','').replaceAll(']','').split(',');
        let formular = values[1];
        for (let i=0;i<index.length;i++)
        {
          formular = formular.replaceAll('['+ index[i] +']', elements!.find(x=> x.id === index[i])!.value!);
        }
        
        let result = eval(formular) as number ;
        newArr!.find(x=> x.id === item.id)!.value = result.toFixed(parseInt(item.fixed!));
        setElements(newArr);
       
      }

   

    });
  }      

  const findBindResults = () => {

    results!.forEach((item) => {
    

      if (item.bind !== undefined)
      {

        let newArr = [...results!];

        let values = item.bind.split('=');
        let index = values[0].replaceAll('[','').replaceAll(']','').split(',');

        let formular = values[1];

        for (let i=0;i<index.length;i++)
        {
          formular = formular.replaceAll('['+ index[i] +']', elements!.find(x=> x.id === index[i])!.value!);
      }

        let result = eval(formular) as number ;
//        newArr!.find(x=> x.id === item.id)!.value = result.toFixed(parseInt(item.fixed!));
        

        newArr!.find(x=> x.id === item.id)!.value = result.toString();

        setResults(newArr);

      }

      if (item.bind2 !== undefined)
      {
        let newArr = [...results!];

        let values = item.bind2.split('=');
        let index = values[0].replaceAll('[','').replaceAll(']','').split(',');

        let formular = values[1];

        for (let i=0;i<index.length;i++)
        {
          formular = formular.replaceAll('['+ index[i] +']', results!.find(x=> x.id === index[i])!.value!);
        }

        let result = eval(formular) as number ;
        newArr!.find(x=> x.id === item.id)!.value = result.toString();
        setResults(newArr);
      }
    });
  }

  const binding = () =>{
    findBindElements();
    findBindResults();
    //setElementsdata({elements: JSON.parse(JSON.stringify(elements!)), results: JSON.parse(JSON.stringify(results!)), settings: JSON.parse(JSON.stringify(settings!))});


    const arr =  JSON.parse(JSON.stringify(readElementsdata!)) as  IElementsDataAtom[];
    

    let elementDataClone = arr?.find(x => x.siteId == prop.siteId);
    
    if (elementDataClone === undefined){
      arr.push({siteId: prop.siteId, elements: JSON.parse(JSON.stringify(elements!)), results: JSON.parse(JSON.stringify(results!)), settings: JSON.parse(JSON.stringify(settings!))})
    }
    else
    {
      elementDataClone.elements = JSON.parse(JSON.stringify(elements!));
      elementDataClone.results = JSON.parse(JSON.stringify(results!));
      elementDataClone.settings = JSON.parse(JSON.stringify(settings!));
    }
    
    setElementsdata(arr);


  }


    React.useEffect(() => {
        if (elements !== undefined && results !== undefined){
         if (onTimeOnlyBindList.current.find(x => x === prop.siteId) === undefined){
          binding();
          onTimeOnlyBindList.current.push(prop.siteId);
         }
         
          //if (onTimeOnly.current)
         //{
         // binding();
         // onTimeOnly.current = false;
         //}
          prop.onChange(elements,results);
          if (settings !== undefined){
            prop.onSettings(settings);
          }
        }
      // eslint-disable-next-line
      }, [elements]);


    //init data 
    React.useEffect(() => {
      if (queryParams.get("code") !== null && queryParams.get("type") === "dynamic"){
        loadConfigRequest({email:"", code:queryParams.get("code")!});
      }
      else
      {
        if (readElementsdata.find(x => x.siteId == prop.siteId) != undefined)
        {
           const data = readElementsdata.find(x => x.siteId == prop.siteId);

          setElements( JSON.parse(JSON.stringify(data!.elements)));  
          setResults(JSON.parse(JSON.stringify(data!.results)));
          setSettings(JSON.parse(JSON.stringify(data!.settings)));
          prop.onLoaded();
        } 
        else{
          defaultFn({id:prop.siteId});
        }  
      }
      // eslint-disable-next-line
    }, [prop.siteId]);


    // load data Default
    React.useEffect(() => {
        if (stateMenu === "SUCCESS"){
          if (responseMenu !== undefined)
          {
              let obj = JSON.parse(responseMenu[0].data) as IConfig
              setElements(obj.elements);  
              setResults(obj.results);
              setSettings(obj.settings);
              prop.onLoaded();
          }
        }
      // eslint-disable-next-line
      }, [stateMenu]);

    const onChangeFieldValue = React.useCallback(
      (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {

        let currentValues = cutDecimal(newValue!.toString());

        // console.log(currentValues.int + "." + currentValues.dec + " <<<<<");

        if (currentValues.isDecimal === false){
          changeElementValue(event.currentTarget.dataset.id!,currentValues.int);  
        }
        else{
          changeElementValue(event.currentTarget.dataset.id!,currentValues.int + "." + currentValues.dec);
        }



        //changeElementValue(event.currentTarget.dataset.id!,newValue!);
    },
      [elements],
    );      

    const onChangeSliderValue = React.useCallback((value:any,range:any,event:any, id:string) => {
      changeElementValue(id,value!);
      },
      [elements],
    );   


    // load data dynamic
    React.useEffect(() => {
      if (stateLoad === "SUCCESS"){
        if (responseLoad !== undefined)
        {
            let obj = JSON.parse(responseLoad[0].configData!) as IConfig;
            setElements(obj.elements);
            setResults(obj.results);
            setSettings(obj.settings);
            setSaveInput((...prevState) => ({prevState,name:responseLoad[0].name, email:responseLoad[0].email, id:responseLoad[0].id, code:responseLoad[0].code, password:responseLoad[0].password, sendEmail:responseLoad[0].sendEmail, pathname: window.location.pathname, type: 'dynamic' }));
            prop.onLoaded();
          }
      }
    // eslint-disable-next-line
    }, [stateLoad]);




    return (
        <>
        
        
        { 
            
          elements?.map((item) => 
          (
            <span key={item.id}>
            {
          item.elementType === "textbox" ?       
          <fluentui.TextField
            key={item.id}
            id={item.id}
            styles={colorTextFieldStyles}
            data-id={item.id}        
            label={langaugeConvert(item.labelName, languageMap, false)}
            data-label={item.labelName}
            value={TextFieldFormatNumber(item.value!.toString())}
            readOnly={item.readonly || false}
            onChange={onChangeFieldValue}/>
            
          :null
            }
            {       
          item.elementType === "slider" ?       
          <fluentui.Slider
            key={item.id}  
            id={item.id}
            styles={colorStyles}
            data-id={item.id}        
            label={langaugeConvert(item.labelName, languageMap, false)}
            data-label={item.labelName}
            min={parseInt(item.min!)}
            max={parseInt(item.max!)}
            value={parseInt(item.value!)}
            onChange={(value, range, event) => onChangeSliderValue(value,range,event,item.id)}
            />
          :null
            }

          {       
          item.elementType === "space" ?       
          <Space key={item.id} id={item.id} height={parseInt(item.value!)} />
          :null
            }
            {       
          item.elementType === "heading" ?       
          <Heading key={item.id} id={item.id} text={langaugeConvert(item.value!, languageMap, false)} />
          :null
            }            
          </span>    
          )
          )
      }

     </>
    )
}